<template>
    <div>
        <v-col cols="auto">
            <v-dialog
            v-model="dialog"
            persistent
            width="500"
            >
                <v-card>
                    <v-toolbar
                    color="primary"
                    dark
                    >
                    cliente: {{node.nombre}}
                    </v-toolbar>
                    
                    <v-card-text>
                        <div class="text-h6 pa-2 text-center primary--text">ACCIONES</div>

                        <div align="center">
                            <v-btn
                            color="success"
                            >+ HERMANO</v-btn>
                            <v-btn
                            color="success"
                            class="ml-4"
                            >+ HIJO</v-btn>
                        </div>

                        <v-textarea
                        class="mx-2"
                        label="comentario"
                        rows="1"
                        prepend-icon="mdi-comment"
                        ></v-textarea>
                        
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions class="justify-end">
                        <v-btn
                            text
                            color="primary"
                            @click="dialog = false"
                        >Guardar</v-btn>
                        <v-btn
                            text
                            color="error"
                            @click="dialog = false"
                        >Cerrar</v-btn>
                    </v-card-actions>

                </v-card>
            </v-dialog>
        </v-col>
    </div>
</template>

<script>
export default {
     props: ["dialog", "node"],
}
</script>
