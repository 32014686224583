<template>
  <div>
    <RedAfiliados :json="data" @click-node="clickNode" />
    <Dialogo :dialog="dialog" :node="node"/>
  </div>
</template>

<script>
import RedAfiliados from "@/components/redafiliados";
import Dialogo from "@/components/redafiliados/dialog";

export default {
  components: {
    RedAfiliados,
    Dialogo
  },
  data() {
    return {
      data: {
        nombre: 'root',
        
        class: ["rootNode"],
        hijo: [
          {
            nombre: 'hijo1',
          },
           {
            nombre: 'hijo1',
          },
          {
            nombre: 'hijo2',
            hijo: [
              {
                nombre: 'subhijo',
              },
              {
                nombre: 'subhijo2',
              },
              {
                nombre: 'subhijo3',
              }
            ]
          }
        ]
      },

      dialog: false,
      node: null
    }
  },

  methods: {
    clickNode: function(node){
      console.log(node)
      this.node = node
      this.dialog = true
    }
  }
}
</script>

<style scoped>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
#app .avat{border-radius: 2em;border-width:2px;}
#app .name{font-weight:700;}
#app .rootNode .name{
  color: red;
}

</style>
